import { NgModule} from '@angular/core';
import { BrowserModule, HammerModule} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { File } from '@ionic-native/file/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ParentComponent } from './parent/parent.component';
import { HttpClientModule } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FileTransferObject, FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Camera} from '@ionic-native/camera/ngx';
import { ActionSheet } from '@ionic-native/action-sheet/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { SharedModule } from './shared.module';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';

@NgModule({
  declarations: [
    AppComponent,
    ParentComponent
  ],

  entryComponents: [],

  imports: [
    SharedModule,
    BrowserModule,
    IonicModule.forRoot({scrollAssist:false,scrollPadding:false}),
    AppRoutingModule,
    HttpClientModule,
    HammerModule
  ],

  providers: [
    InAppBrowser,
    FilePath,
    StatusBar,
    SplashScreen,
    SQLite,
    // Firebase,
    FirebaseX,
    Device,
    Keyboard,
    ScreenOrientation,
    Badge,
    File,
    FileTransferObject,
    FileTransfer,
    FileOpener,
    Camera,
    ActionSheet,
    AppVersion,
    CallNumber,
    FingerprintAIO,
    OpenNativeSettings, 
    DatePicker,
    Contacts,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
