import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import { Observable, Observer, Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/delay';
import hiBase64 from 'hi-base64';
import Base64 from 'Base64';
import utf8 from 'utf8';
import {v4 as uuid} from "uuid"; 
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class RestProviderService {

  timeout: number = 60; // seconds
  arrayJson: any = [];

  constructor(
    public http: HttpClient,
    public sharedData: SharedDataService
  ) {
  }

  public async getUserPreferences(): Promise<any> {
    return new Promise((resolve, reject) => {
      let platform=(<any>window).cordova!=undefined ? 'mobile':'web';
      this.executeMethod('BW2Mobile_GetUserSettings', {platform:platform}).then(result => {
        let userSettingsPlugin:any[]=[];
        if (result != '{}') {
          this.sharedData.userSettings=JSON.parse(result);
        }else {
          this.sharedData.userSettings=[];
        }
        if(this.sharedData.userSettings.length>0)
        {
          userSettingsPlugin=this.sharedData.userSettings.filter(us => us['view_id'] &&  us['plugin_id']);
        }
        if(userSettingsPlugin==undefined)
          userSettingsPlugin=[];
        resolve(userSettingsPlugin);
      }, error => {
        this.sharedData.userSettings=[];
        console.error(error);
        reject(error);
      });
    });
  }

  public async executeDashboardMethod(request:any,routeApi:string): Promise<any> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post<any>(this.sharedData.DashboardUrl+routeApi,request ,{headers}).subscribe(result => {
        resolve(result);
      }, error => {
        console.error(error);
        reject(error);
      });
    });
  }

  private async executeSaveUserPreference(params:any): Promise<any>{
    return this.executeMethod('BW2Mobile_SaveUserSettings', params).then(result => {
      return result;
    }, error => {
      console.error(error);
    });
  }

  public async saveUserPreferencesBrowse(browseGuid: string, settings: any): Promise<any> {
    settings['browse_Guid'] = browseGuid;
    let platform=(<any>window).cordova!=undefined ? 'mobile':'web';
    let userSetting=this.sharedData.userSettings.find(us => us['browse_Guid'] == browseGuid && us['platform'] && us['platform'].toLowerCase() == platform.toLowerCase());
    if(userSetting)
    {
      let data = {};
      data = JSON.parse(userSetting['data']);
      Object.keys(settings).forEach((key) => {
        data[key]=settings[key];
       });
      userSetting['data']=JSON.stringify(data);
    }else{
      userSetting={};
      userSetting['browse_Guid']=browseGuid;
      userSetting['data']=JSON.stringify(settings);
      userSetting['platform']=platform;
      this.sharedData.userSettings.unshift(userSetting);
    }
    let params = {
      browseGuid: browseGuid,
      userSettings: userSetting['data'],
      platform:platform
    };
    return this.executeSaveUserPreference(params);
  }

  public async saveUserPreferencesCard(viewId: number, pluginId: number,cardId:number,settings: any): Promise<any> {
    settings['view_id'] = viewId;
    settings['plugin_id'] = pluginId;
    settings['card_id'] = cardId;
    let platform=(<any>window).cordova!=undefined ? 'mobile':'web';
    let userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId &&  us['plugin_id'] == pluginId &&  us['card_id'] == cardId && us['platform'] && us['platform'].toLowerCase() == platform.toLowerCase());
    if(userSetting)
    {
      userSetting['lastUpdated']=new Date();
      let data = {};
      if(userSetting)
      {
        data = JSON.parse(userSetting['data']);
      }
      Object.keys(settings).forEach((key) => {
          data[key]=settings[key];
      });
      userSetting['data']=JSON.stringify(data);
    }else{
      userSetting={};
      userSetting['lastUpdated']=new Date();
      userSetting['view_id']=viewId;
      userSetting['plugin_id']=pluginId;
      userSetting['card_id']=cardId;
      userSetting['data']=JSON.stringify(settings);
      userSetting['platform']=platform;
      this.sharedData.userSettings.unshift(userSetting);
    }
    this.sharedData.userSettings.sort(function(a,b){
      if(a.lastUpdated && b.lastUpdated)
      {
        if(a.lastUpdated>b.lastUpdated)
        {
          return -1;
        }else{
          return 1;
        }
      }else{
        if(a.lastUpdated)
        {
          return -1;
        }else if(b.lastUpdated){
          return 1
        }else{
          return 0;
        }
      }
    });
    let params = {
      viewId: viewId,
      pluginId: pluginId,
      cardId: cardId,
      userSettings: userSetting['data'],
      platform:platform
    };
    return this.executeSaveUserPreference(params);
  }

  public async saveUserPreferences(viewId: number, pluginId: number,settings: any): Promise<any> {
    settings['view_id'] = viewId;
    settings['plugin_id'] = pluginId;
    let platform=(<any>window).cordova!=undefined ? 'mobile':'web';
    let userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId &&  us['plugin_id'] == pluginId && us['platform'] && us['platform'].toLowerCase() == platform.toLowerCase() && us['card_id']==undefined);
    if(userSetting)
    {
      userSetting['lastUpdated']=new Date();
      let data = {};
      if(userSetting)
      {
        data = JSON.parse(userSetting['data']);
      }
      Object.keys(settings).forEach((key) => {
          data[key]=settings[key];
      });
      userSetting['data']=JSON.stringify(data);
    }else{
      userSetting={};
      userSetting['lastUpdated']=new Date();
      userSetting['view_id']=viewId;
      userSetting['plugin_id']=pluginId;
      userSetting['data']=JSON.stringify(settings);
      userSetting['platform']=platform;
      this.sharedData.userSettings.unshift(userSetting);
    }
    this.sharedData.userSettings.sort(function(a,b){
      if(a.lastUpdated && b.lastUpdated)
      {
        if(a.lastUpdated>b.lastUpdated)
        {
          return -1;
        }else{
          return 1;
        }
      }else{
        if(a.lastUpdated)
        {
          return -1;
        }else if(b.lastUpdated){
          return 1
        }else{
          return 0;
        }
      }
    });
    let params = {
      viewId: viewId,
      pluginId: pluginId,
      userSettings: userSetting['data'],
      platform:platform
    };
    return this.executeSaveUserPreference(params);
  }

  public async executeMethod(method: string, params: any): Promise<any> {
    let performRequest :boolean= true;
    let groupOrderBy;
    if(method == "BW2Mobile_GetGroupOrderBy"){
      if(params.itemId == undefined && params.itemBoId == undefined){
        groupOrderBy =  window.localStorage.getItem(params.itemGuid);
        if(groupOrderBy){
          performRequest = false;
        }        
      }      
    }
    
    if(performRequest){
      return this.executeHttpMethod(method, params).toPromise();
    }else{
      return new Promise(resolve => resolve(groupOrderBy));
    }    

  }

  public executeHttpMethod(method: string, params: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    headers = headers.set('MethodName', method).set('DeviceGUID', this.sharedData.deviceGuid).set('PortalGUID', this.sharedData.portalGuid).set('sessionKey', this.sharedData.sessionKey);

    if (params.username != undefined) {
      headers = headers.set('UserWeb', params.username);
    }

    let timeout = this.timeout * 1000;

    let requestOptions: any = {
      headers: headers,
      responseType: 'text' // arraybuffer || blob || json(default) || text
    };

    if (method == 'BW2Mobile_GetFile') {
      requestOptions.responseType = 'arraybuffer';
      timeout = this.timeout * 6000; // 180 seconds
    }

    if (method == 'BW2Mobile_ValidateUser') {
      timeout = this.timeout * 3000; //90 seconds
    }

    if (params.top == undefined) {
      params.top = this.sharedData.queryTop;
    }
    let url = '';
    if (method == 'GetSessionKeyDevice' || method == 'GetSessionKeyWebUser') {
      url = this.sharedData.UrlDiscovery;
    } else {
      url = this.sharedData.UrlTicketsServerHttp;
    }

    let body: any = new Blob([JSON.stringify(params)]);

    if (method == 'BW2Mobile_AttachFiles' || method == 'BW2Mobile_AttachFilesForMobile' || method == 'BW2Mobile_AttachFilesFromPlugin' || method == 'BW2Mobile_UploadFiles' || method == 'BW2Mobile_UploadDocument' || method == 'BW2Mobile_UploadImage') {
      body = params;
      requestOptions = ({ headers: undefined, responseType: 'text' });

      return Observable.create((observer: Observer<string>) => {
        Observable.create((observer2: Observer<string>) => {
          this.performHttpPostWithoutTimeOut(url, body, requestOptions, method, observer2, 0);
        }).subscribe(
          response=>{
            observer.next(response);
            observer.complete();
          },
          error=>{
            console.log(error);
            Observable.create((observer2: Observer<string>) => {
              this.performHttpPostWithoutTimeOut(url, body, requestOptions, method, observer2, 0);
            }).subscribe(
              response=>{
                observer.next(response);
                observer.complete();},
              err=>{
                console.log(err);
                observer.error(err);
                observer.complete();
              }
            );
          }
        );
      }).catch(this.handleError.bind(this));
    } else {
      if(method == 'BW2Mobile_GetDashboard')
      {
        return Observable.create((observer: Observer<string>) => {
          this.performHttpPostWithoutTimeOut(url, body, requestOptions, method, observer, 0);
        }).catch(this.handleError.bind(this));
      }else{
        return Observable.create((observer: Observer<string>) => {
          this.performHttpPost(url, body, requestOptions, timeout, method, observer, 0);
        }).catch(this.handleError.bind(this));
      }
    }
  }

  private performHttpPostWithoutTimeOut(url: string, body: any, requestOptions: any, method: string, observer: Observer<string>, times: number) {
    this.http.post(url, body, requestOptions).subscribe((response: any) => {
      if (method != 'BW2Mobile_GetFile') {
        if (response.indexOf('ERROR') != -1) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }

          observer.error(err);
        }
        if (response.startsWith('EXCEPTION')) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }

          observer.error(err);
        }
        if (response.indexOf('SUCCESSFUL') == -1) {
          if (method != 'BW2Mobile_ValidateUser') {
            response = utf8.encode(response);
            if (method == 'GetSessionKeyDevice') {
              response = Base64.atob(response);
            } else {
              response = hiBase64.decode(response);
            }
          }
        }
        if (response.startsWith('ERROR - ')) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }
          observer.error(err);
        }
      }
      observer.next(response);
      observer.complete();
    }, error => {
      let err: any = {
        message: error.message,
        method: method
      }
      if (url == this.sharedData.UrlDiscovery) {
        err.message = 'DISCOVERY - ' + err.message;
      } else {
        err.message = 'TICKET SERVICE - ' + err.message;
      }
      observer.error(err);
      observer.complete();
    });
  }

  private performHttpPost(url: string, body: any, requestOptions: any, timeout: number, method: string, observer: Observer<string>, times: number): void {
    this.http.post(url, body, requestOptions).timeout(timeout).subscribe((response: any) => {
      if (method != 'BW2Mobile_GetFile') {
        if (response.indexOf('ERROR') != -1) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }

          observer.error(err);
        }
        if (response.startsWith('EXCEPTION')) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }

          observer.error(err);
        }
        if (response.indexOf('SUCCESSFUL') == -1) {
          if (method != 'BW2Mobile_ValidateUser') {
            response = utf8.encode(response);
            if (method == 'GetSessionKeyDevice') {
              response = Base64.atob(response);
            } else {
              response = hiBase64.decode(response);
            }
          }
        }
        if (response.startsWith('ERROR - ')) {
          let err: any = {
            message: response,
            method: method
          }

          if (url == this.sharedData.UrlDiscovery) {
            err.message = 'DISCOVERY - ' + err.message;
          } else {
            err.message = 'TICKET SERVICE - ' + err.message;
          }

          observer.error(err);
        }
      }

      observer.next(response);
      observer.complete();

    }, error => {
      let err: any = {
        message: error.message,
        method: method
      }
      if (url == this.sharedData.UrlDiscovery) {
        err.message = 'DISCOVERY - ' + err.message;
      } else {
        err.message = 'TICKET SERVICE - ' + err.message;
      }
      observer.error(err);
      observer.complete();
    });
  }

  public registerLog(error: Response | any): void {
    let url = "https://log.businesswideweb.net/LogShippingExternal.ashx";
    let headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    let timeout = this.timeout * 1000;
    let requestOptions: any = {
      headers: headers,
      responseType: 'text'
    };

    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    let body = {
      LogPortalGuid: this.sharedData.portalGuid,
      LogType: "68", //  [Debug] = 64      [Info] = 65      [Warning] = 67      [Error] = 68      [BW2ExceptionHandler] = 69
      LogReason: errMsg,
      LogText: error.method,
      LogStackTrace: errMsg,
      LogHighlight: "0",
      LogSeqNo : "1", 
      LogPortalDestination: this.sharedData.portalGuid
    }
    console.log(body);
    this.http.post(url, body, requestOptions).timeout(timeout).subscribe((response: any) => {
      console.log(response);
    }, error => {
      console.error(error);
    });
  }

  private handleError(error: Response | any) {
    this.registerLog(error);
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error('handleError : ' + errMsg);
    if (errMsg.indexOf('INVALID SESSION KEY') > 0 || errMsg.indexOf('SESSION KEY EMPTY') > 0) {
      if (error.method == 'GetSessionKeyDevice' || error.method == 'GetSessionKeyWebUser' || error.method == 'BW2Mobile_ValidateUser') {
        return Observable.throw(errMsg);
      } else {
        if((<any>window).cordova==undefined)
          (<any>window).location.href = '/';
      }
    }
    else {
      return Observable.throw(errMsg);
    }
  }

  private async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
